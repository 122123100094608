import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Short Arrow Function Are Coming to PHP",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-08T19:59:26.000Z",
  "url": "/2019/05/short-arrow-function-are-coming-to-php/",
  "categories": ["News"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` The new bomb has landed on the PHP community :0 Well I`}{`’`}{`m just joking, but hopefully, soon we all could start using shorthand arrow function on PHP (similar to Javascript) `}</p>
    <pre><code parentName="pre" {...{}}>{`  $extended = function ($c) use ($callable, $factory) {
    return $callable($factory($c), $c);
  };
 
  // with arrow function:
  $extended = fn($c) =&gt; $callable($factory($c), $c);
`}</code></pre>
    <p>{`Short Arrow Functions is targeted for inclusion in PHP v7.4, and you can read more about this on the `}</p>
    <p><a parentName="p" {...{
        "href": "https://wiki.php.net/rfc/arrow_functions_v2"
      }}>{`PHP wiki.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      